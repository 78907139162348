.container {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  max-width: 1440px;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}

.text {
  color: #ffffff;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: #171A1F;
  padding: 20px;
  font-size: 20px;
  margin-bottom: -20px;
  color: #ffffff;
}